import React from 'react'
import Image from 'components/Image'
import LinkWrap from 'components/LinkWrap'
import LanLink from 'components/LanLink'
import Inview from 'components/Inview'
import { content, filterContentByLocale } from 'content/content.js'
import { useLocalization } from 'gatsby-theme-i18n'

const Header = ({
  addClass,
  menuDown,
  mobileMenuOpen,
  toggleMobileMenuOpen,
}) => {
  const { locale } = useLocalization()
  const localeContent = filterContentByLocale(content, locale)
  const { menu } = localeContent

  return (
    <div
      className={`header ${addClass} 
      ${menuDown ? 'menu-down' : ''}
      ${mobileMenuOpen ? 'mobile-menu-open' : ''}
      `}
    >
      <div className='inner'>
        <Thumb
          toggleMobileMenuOpen={toggleMobileMenuOpen}
          mobileMenuOpen={mobileMenuOpen}
        />
        <Inview className='fade-in'>
          <ul className='menu'>
            {menu.map((item, i) => (
              <li key={i}>
                <LinkWrap
                  to={item.to}
                  partiallyActive={item.to === '/' ? false : true}
                >
                  <span>{item.text}</span>
                </LinkWrap>
              </li>
            ))}
            <li>
              <LanLink />
            </li>
          </ul>
        </Inview>
        <div className='logo'>
          <LinkWrap to='/'>
            <Image filename='logo-standard.png' />
          </LinkWrap>
        </div>
      </div>
    </div>
  )
}

export default Header

const Thumb = ({ toggleMobileMenuOpen, mobileMenuOpen }) => {
  return (
    <div
      className={`menu-thumb ${mobileMenuOpen ? 'mobile-menu-open' : ''}`}
      onClick={toggleMobileMenuOpen}
      onKeyDown={toggleMobileMenuOpen}
      role='button'
      tabIndex='0'
    >
      <Inview className='inner fade-in'>
        <div className='one'></div>
        <div className='two'></div>
        <div className='three'></div>
      </Inview>
    </div>
  )
}
