export const content = {
  home: {
    banner: {
      title: {
        en: `Technologies Devoted 
        to Safety`,
        zh: `展科技之翼
        用心构筑安全`,
      },
      slides: [
        {
          image: `bj-daxing-ariport-3.jpg`,
          imageMobile: `bj-daxing-ariport-3-mobile.jpg`,
          caption: {
            en: `Beijing Daxing International Airport`,
            zh: `北京大兴国际机场`,
          },
        },
        {
          image: `bj-cctv-2.jpg`,
          imageMobile: `bj-cctv-2-mobile.jpg`,
          caption: {
            en: `CCTV Headquarters`,
            zh: `中央电视台总部大楼`,
          },
        },
        {
          image: `37019136.jpg`,
          imageMobile: `37019136-mobile.jpg`,
          caption: {
            en: `Beijng Metro System`,
            zh: `北京地铁`,
          },
        },
      ],
    },
    textOnly: {
      title: {
        en: `About Us`,
        zh: `关于我们`,
      },
      description: {
        en: `Better buildings make our lives better. At Leege, we’re utilizing IoT technologies to create holistic safety solutions, to support buildings through providing intelligent products, customized smart platforms and operation and management service in full life cycle.`,
        zh: `我们是建筑安全系统解决方案的提供商，运用物联网技术，构建智慧安全集成体系，为建筑提供智能产品、智慧建造以及全生命周期的安全运行和管理服务。`,
      },
      description2: {
        en: `We set the trend for digital technologies, empower urban safety, and pioneer the digital transformation of construction implementation, operation, and management for a greener, safer, more intelligent and efficient city.`,
        zh: `引领数字科技，为城市安全赋能，引领建设实施和运行管理向数字化转型，让城市更智能、绿色、安全和高效。 `,
      },
      link: {
        to: `/about/`,
      },
    },
    table: {
      title: {
        en: `Our Business`,
        zh: `我们的业务`,
      },
      items: [
        {
          icon: `icon-cloud.png`,
          title: {
            en: `Data-Driven Smart Platforms`,
            zh: `数字驱动智慧平台`,
          },
          description: {
            en: `A customizable safety management platform for buildings connects people and devices and between devices, constructing end-to-end IoT solutions. Information is collected, summarized, and analyzed during project implementation; the safety management platform is used during project operation to reasonably reduce costs, effectively control risks, and improve management efficiency, thereby increasing project value and promoting enterprise digitalization.`,
            zh: `提供可定制化的建筑安全管理平台，联接人与物，物与物，构建端到端物联网解决方案。项目实施过程中对信息进行采集、汇总和分析；项目运行中利用建筑安全管理平台合理降低成本、有效控制风险并提升管理效率，从而增益项目价值，推动企业数字化。`,
          },
        },
        {
          icon: `icon-signal.png`,
          title: {
            en: `Technology-Defined Safe Operation`,
            zh: `科技定义安全运营`,
          },
          description: {
            en: `The safe operation and management of buildings are redefined using brand new IoT technologies, covering dynamic sensing, intelligent research and judgment, accurate surveillance deployment and smart analytics.`,
            zh: `通过动态感知、智能研判、精准布控，决策分析，以全新的物联网技术重新定义建筑的安全运营管理。`,
          },
        },
        {
          icon: `icon-remote.png`,
          title: {
            en: `Seamless Access of Smart Products`,
            zh: `智能产品无缝接入`,
          },
          description: {
            en: `Intelligent system integration products, including firefighting, security, building control, energy management, etc., are the basic units of the information network platform to connect with the overall information management platform seamlessly.`,
            zh: `选用智能化的系统集成产品: 包括消防、安防、楼控和能源管理等，作为信息网络平台搭建的基础单元，实现与整体信息化管理平台的无缝衔接。 `,
          },
        },
        {
          icon: `icon-abstract.png`,
          title: {
            en: `Data Quantitative Evaluation Consulting`,
            zh: `数据量化评估咨询`,
          },
          description: {
            en: `Big data adds a new perspective to the establishment and operation of intelligent systems in buildings and cities. Consultation solutions are customized based on the data to focus on user experience.`,
            zh: `大数据为建筑智能系统的建立和运行增添新的视角。定制化的顾问咨询方案，从用户体验出发，让数据说话，直击问题痛点。`,
          },
        },
      ],
      link: {
        to: `/business/`,
      },
    },
    slideshow: {
      title: { en: `Industry Solutions`, zh: `行业解决方案` },
      slides: [
        {
          slug: 'airports',
          image: `tianjing-airport-T2.jpg`,
          title: {
            en: `Airport`,
            zh: `民航机场`,
          },
          excerpt: {
            en: `We have served in the airport industry for over 15 years, striving to build smart, technology-oriented, culturally enriched, and green airports.`,
            zh: `专注服务机场行业超过15年，助力打造智慧、科技、人文、绿色四型机场。`,
          },
        },
        {
          slug: 'metro',
          image: `图虫创意-902620336891101312.jpg`,
          title: {
            en: `Metro`,
            zh: `轨道交通
            `,
          },
          excerpt: {
            en: `We have accumulated 16 years of experience in implementing rail transit projects and provided all-around assurance for the safe and efficient operation of 12 metro lines. With Leege’s product, technologies, and service, it is safer and more convenient to travel by metro.`,
            zh: `16年轨道交通项目实施经验，为12条地铁线路的安全高效运行提供全力保障 ，力景的产品+技术+服务三重保障，让地铁运行更安全、快捷。`,
          },
        },
        {
          slug: 'complex',
          image: `Beijing Genesis Center北京启皓中心.jpg`,
          title: {
            en: `Super-Large Building Complexes`,
            zh: `超大综合体`,
          },
          excerpt: {
            en: `Leege delivers the intelligence and fire safety design of buildings based on BIM technology, facilitating safe building operation throughout the building life cycle.`,
            zh: `力景基于BIM技术参与建筑智能化和消防安全设计，帮助实现建筑全生命周期的安全运营。`,
          },
        },
        {
          slug: 'hotel',
          image: `The Ritz-Carlton Beijing, Financial Street北京金融街丽思卡尔顿酒店.jpg`,
          title: {
            en: `Hotels`,
            zh: `酒店`,
          },
          excerpt: {
            en: `With quality service and professional technologies, we can easily cope with the dual challenges in five-star hotels and high-end sites.`,
            zh: `对于五星酒店及高端商业，优质服务和专业技术使我们可以从容面对双面挑战。`,
          },
        },
        {
          slug: 'landmark',
          image: `bj-cctv-2.jpg`,
          title: {
            en: `City Landmarks`,
            zh: `城市地标`,
          },
          excerpt: {
            en: `For super‐high‐rise landmark buildings and super-large complex buildings, which pose extremely high safety requirements, Leege’s effective management platform brings together hardware and software products, service, and professional teams to create beautiful urban skylines and landscapes.`,
            zh: `无论是超高层地标建筑，还是超大体量综合体对安全性功能都有极高要求，通过提供软、硬件产品服务， 配置专业团队，输出有效管理平台，合力共筑美丽的城市天际线和风景线。`,
          },
        },
        {
          image: `Beijing IKEA Huiju Shopping Center北京宜家荟聚购物中心.jpg`,
          title: {
            en: `Commercial Sites`,
            zh: `商业`,
          },
          excerpt: {
            en: `Meet the high standards by international management companies to create great experience for customers in the buildings.`,
            zh: `满足高净值顾客的良好体验需求和国际化管理公司的高标准要求。`,
          },
        },
      ],
      link: {
        to: `/solutions/`,
        text: {
          en: `More Solutions`,
          zh: `更多解决方案`,
        },
      },
    },
    bannerMid: {
      image: `交通环境地下铁路摄影图片素材_crop.jpg`,
      imageMobile: `交通环境地下铁路摄影图片素材_crop-mobile.jpg`,
      title: {
        en: `20 Years`,
        zh: `20年`,
      },
      subtitle: {
        en: `Professional Experience`,
        zh: ` 专业领域`,
      },
    },
    titleStandalone: {
      en: `Leege at a Glance`,
      zh: `走近力景`,
    },
    textImageNumbers: {
      image: `bj-subway-line4.jpg`,
      text: {
        title: {
          en: `Airports and Metro`,
          zh: `民航机场和轨道交通`,
        },
        excerpt: {
          en: `We have served in the airport industry for over 15 years，and provided all-around assurance for the safe and efficient operation of 12 metro lines.`,
          zh: `专注服务机场行业超过15年，为12条地铁线路提供安全保障。`,
        },
        numbers: [
          {
            number: {
              en: 5,
              zh: 500,
            },
            numberText: {
              en: ` million`,
              zh: `万`,
            },
            unit: {
              en: `sqm`,
              zh: `平米`,
            },
            icon: `icon-plane.png`,
            excerpt: {
              en: `airport 
            terminals`,
              zh: `机场
              航站楼`,
            },
          },
          {
            number: 300,
            unit: {
              en: `km`,
              zh: `公里`,
            },
            icon: `icon-transit.png`,
            excerpt: {
              en: `Overall rail transit
            solution and safe 
            O&M of tracks`,
              zh: `轨道交通整体解决方案
              及安全运维`,
            },
          },
        ],
      },
    },
    textImageNumbers2: {
      image: `bj-cctv-2.jpg`,
      text: {
        title: {
          en: `Construction Safety Management`,
          zh: `建筑安全管理`,
        },
        excerpt: {
          en: `Leege will continue to create values for clients with technologies innovation in building safety.`,
          zh: `不断在建筑安全领域实现技术创新，成就客户价值。`,
        },
        numbers: [
          {
            number: {
              en: 1,
              zh: 100,
            },
            numberText: {
              en: `+ million`,
              zh: `+万`,
            },
            unit: {
              en: `sqm`,
              zh: `平米`,
            },
            icon: `icon-landmark.png`,
            excerpt: {
              en: `large building 
            complexes`,
              zh: `大型建筑
              综合体`,
            },
          },
          {
            number: { en: 10, zh: 1000 },
            numberText: {
              en: `+ million`,
              zh: `+万`,
            },
            unit: {
              en: `sqm`,
              zh: `平米`,
            },
            icon: `icon-building.png`,
            excerpt: {
              en: `buildings under 
            24/7 protection`,
              zh: `建筑受到24小时
              全天候保护`,
            },
          },
        ],
      },
    },
  },
  about: {
    banner: {
      image: '729A8561-HDR-编辑-crop.jpg',
      imageMobile: '729A8561-HDR-编辑-mobile.jpg',
      title: {
        en: `About Us`,
        zh: `关于我们`,
      },
    },
    textImage: {
      section: {
        en: `What will the future be like?`,
        zh: `关于未来的想象，应是怎样？`,
      },
      highlight: {
        en: `We have been looking for answers in our 20 years of development.`,
        zh: `我们在20年的成长历程中寻找答案。`,
      },
      body: {
        en: `<p>By continuing to play an active role in the field of construction safety. In this era when digitalization is revolutionizing the world, we pave the way to future technologies.</p><p>We build smart systems for building safety by utilizing IoT, big data, and other emerging technologies to offer customized professional system solutions and digital asset transformation service for airports, metro, hotels, commercial complex buildings, theme parks, residential and other industries. We strive to promote smart city construction, safe operation, and management empowerment.</p>`,
        zh: `<p>持续在建筑安全领域中发挥积极作用，在数字化驱动世界变革的时代，为未来开启全新科技启航之路。</p><p>我们构建建筑安全智慧系统，运用物联网、大数据等新兴技术业态，为民航机场、轨道交通、星级酒店、商业综合体、主题文旅、人居安全等提供定制化专业系统解决方案及数字化资产转型服务，为推动智慧化城市建设、安全运营和管理赋能而努力。 </p>`,
      },
      image: `bj-subway-line4-crop.jpg`,
      caption: {
        en: `The future is here. Leege will continue to create values for clients with technological innovation. We build technologies devoted to safety.`,
        zh: `未来已来，力景将不断技术创新，成就客户价值。展科技之翼，用心构筑安全。`,
      },
    },
    bannerMid: {
      image: `北京启皓中心.jpg`,
      imageMobile: `北京启皓中心-mobile.jpg`,
      title: {
        en: `Vision`,
        zh: `愿景`,
      },
      subtitle: {
        en: `Make smart cities securer for citizens to feel safer by pioneering the building technologies development.`,
        zh: `引领行业科技发展，让智慧城市更为安全安心。`,
      },
    },
    textImage2: {
      section: {
        en: `Sustainability`,
        zh: `可持续发展`,
      },
      highlight: {
        en: `Emphasizing both livings for the moment and future growth, we are concerned about social improvement, economic development, and environmental friendliness.`,
        zh: `关注当下的同时着眼于未来，我们关注社会的改善、经济的发展和与环境的友好。`,
      },
      body: {
        en: `<p>Improve energy efficiency, safety, and productivity, while cutting resource consumption and environmental pollution, for the ecological development of buildings.</p><p>Focus on technological innovation for both industrial and economic development.</p>`,
        zh: `<p>提升建筑的能效、安全性与生产力，同时降低资源消耗和环境污染，促进建筑的生态化发展。 </p><p>关注科技创新，促进行业和经济的发展。</p>`,
      },
      image: `大兴机场内部.jpg`,
    },
  },
  business: {
    banner: {
      image: `The St. Regis Tianjin天津瑞吉酒店.jpg`,
      imageMobile: `The St. Regis Tianjin天津瑞吉酒店-mobile.jpg`,
      title: {
        en: `Business Mode`,
        zh: `业务模式`,
      },
    },
    textImage: {
      title: {
        en: `Digital Technologies Empower City Safety`,
        zh: `数字科技为城市安全赋能`,
      },
      highlight: {
        en: `The internet of things (IoT) technology enables the communication between things and between people and things. `,
        zh: `物联网科技让物与物之间，
        人与物之间形成沟通。`,
      },
      body: {
        en: `<p>Smart buildings can interact with people anytime through data models. We focus on the ever-changing needs of users and long-term benefits and empower buildings with smart IoT, enabling the safe, reliable, sustainable, and long-term development of buildings.</p>`,
        zh: `<p>物联网科技让物与物之间，人与物之间形成沟通，智慧建筑通过数据模型与我们随时交互。我们关注不断变化的用户需求和更长远的利益，让智慧物联为建筑赋能，让建筑安全可靠、可持续和长期发展。</p>`,
      },
      image: `iStock-1015548248-bg.png`,
    },
    textImageTable: {
      section: {
        en: `Past, present, future`,
        zh: `过去，现在和未来`,
      },
      items: [
        {
          pretitle: {
            en: `In the past`,
            zh: `过去`,
          },
          title: {
            en: `Hardware + Team`,
            zh: `硬件+团队`,
          },
          body: {
            en: `<p>Cost-effective hardware products and professional teams are employed for closed-loop management throughout the workflow of building safety systems from design application and construction to operation and maintenance. Extensive project experience is summarized for continuous improvement.</p>`,
            zh: `<p>通过选择高性价比的硬件产品和选派专业的团队，实现对建筑安全系统从设计应用、建设实施到运营维保的全业务流程闭环管理，并不断总结经验，进行持续改进。</p>`,
          },
          image: `iStock-1020698182-bg.png`,
        },
        {
          pretitle: {
            en: `In the future`,
            zh: `未来`,
          },
          title: {
            en: `Data + Intelligence`,
            zh: `数据+智能`,
          },
          body: {
            en: `<p>Data will be harnessed for comprehensive sensing and integrated development, tapping into the huge potential of intelligence in improving quality and efficiency. Building safety command systems will become the entrances to smart cities, making it possible to converge more technologies.</p>`,
            zh: `<p>通过数据全面感知、融通发展，深入挖掘智能带来的巨大提质增效的价值空间。建筑安全指挥系统将成为智慧城市的一个链接入口，使更多科技叠加成为可能。</p>`,
          },
          image: `iStock-1020698160-bg.png`,
        },
        {
          pretitle: {
            en: `Nowadays`,
            zh: `现在`,
          },
          title: {
            en: ``,
            zh: ``,
          },
          body: {
            en: `<p>Let’s embrace change and embark on a new journey.</p>`,
            zh: `<p>让我们拥抱变化，再度启航。</p>`,
          },
          image: `iStock-1147727236-bg.png`,
        },
      ],
    },
    bannerMidIllustrated: {
      image: `iStock-1131738329-bg.png`,
      title: {
        en: `Core Business`,
        zh: `核心业务模块`,
      },
      subtitle: {
        en: `Digital Technologies Empower City Safety`,
        zh: `数字科技为城市安全赋能`,
      },
    },
    accordion: {
      rows: [
        {
          icon: `icon-cloud.png`,
          title: {
            en: `Data-Driven Smart Platform`,
            zh: `数字驱动智慧平台`,
          },
          highlight: {
            en: `Build an IoT database, reshape operation management, and accumulate data assets`,
            zh: `搭建物联数库，重塑运行管理，积淀数据资产`,
          },
          description: {
            en: `We help clients to carry out the digital transformation of building safety management. The collection, reporting, analysis of, and response to information form a closed loop to realize a centralized platform of information, standardized response, clear scopes of responsibility, efficient management, and intelligent analysis.`,
            zh: `帮助客户在建筑安全管理业务上进行数字化转型，信息采集、上报、分析、处置形成闭环，从而实现信息平台化、 处置规范化、责任清晰化、管理高效化、分析智能化。 `,
          },
          points: {
            en: `<ul>
            <li>Map-based management for real-time awareness</li>
            <li>Intelligent research and judgment for graded early warnings</li>
            <li>Examination reports for safety assessment and risk control</li>
            <li>Clear scopes of responsibility for accountability implementation</li>
            <li>Digital business for higher efficiency and lower costs</li>
            </ul>`,
            zh: `<ul>
            <li>地图管理、实时掌控</li>
            <li>智能研判、分级预警</li>
            <li>体检报告、安评控险</li>
            <li>责任清晰、追责到位</li>
            <li>数字业务、降本增效</li>
            </ul>`,
          },
        },
        {
          icon: `icon-signal.png`,
          title: {
            en: `Technology-Defined Safety`,
            zh: `科技定义安全运营`,
          },
          highlight: {
            en: `Construct a professional safety assurance system for customers based on data analysis, classification, and identification`,
            zh: `数据分析、分类识别，为客户构建专业化安全运营保障体系`,
          },
          description: {
            en: `The IoT platform serves as a comprehensive service assurance system capable of real-time operating status monitoring through Internet connections, sending alarms of faults, positioning and tracking, remote control, remote maintenance, security protection, online upgrades, and alarm correlation. Leege provides:`,
            zh: `运用物联网平台实时联网运行状态监测、故障报警、定位追溯、远程控制、远程维保、安全防范、在线升级和报警联动，实现全方位服务保障体系。`,
          },
          points: {
            en: `<ul>
            <li>24/7 firefighting, security, and energy monitoring through accurate surveillance deployment</li>
            <li>Full-view inspection to mitigate hidden risks</li>
            <li>Preventative maintenance testing to eliminate faults and carry out upgrades and reconstruction</li>
            <li>All-weather intelligent operation management, quick decision making, timely response, and efficient service</li>
            </ul>`,
            zh: `<ul>
            <li>7*24消防、安防、楼宇、能源监控，精准布控</li>
            <li>全视角巡视，排查隐患</li>
            <li>预防性维护测试，清除故障，升级改造</li>
            <li>实现全天候运营管理智能化，快速决策，及时处置，高效服务</li>
            </ul>`,
          },
        },
        {
          icon: `icon-remote.png`,
          title: {
            en: `Seamless Access of Smart Products`,
            zh: `智能产品无缝接入`,
          },
          highlight: {
            en: `Provide abundant options of basic units to be built into the IoT for buildings`,
            zh: `提供丰富的建筑物联网信息网络化搭建基础单元的选择`,
          },
          description: {
            en: `Empowered by Fortune Global 500 companies’ superior technical support for intelligent firefighting, security, building control, and energy management products:`,
            zh: `全球500强集团旗下消防、安防、楼控、能源管理智能产品的金牌技术服务`,
          },
          points: {
            en: `<ul>
            <li>Notifier, top brand of fire alarm systems</li>
            <li>Honeywell security systems</li>
            <li>Honeywell building control systems</li>
            <li>Honeywell energy management systems</li>
            <li>Intelligent system integration products, including both software and hardware, can seamlessly access the overall information management platform.</li>
            </ul>`,
            zh: `<ul>
            <li>消防报警系统品牌NOTIFIRE</li>
            <li>霍尼韦尔安防系统</li>
            <li>霍尼韦尔楼控系统</li>
            <li>霍尼韦尔能源管理系统</li>
            <li>智能化的系统集成产品，软件与硬件均可实现与整体信息化管理平台的无缝衔接</li>
            </ul>`,
          },
        },
        {
          icon: `icon-abstract.png`,
          title: {
            en: `Consultation on Quantitative Data Evaluation`,
            zh: `数据量化评估咨询`,
          },
          highlight: {
            en: `Customize special consultation solutions for clients by quantifying data and clarifying key elements`,
            zh: `数据量化，厘清要素，为客户提供专享的定制化顾问咨询方案`,
          },
          description: {
            en: `We extract building parameters and key elements in our solutions, analyze and quantify data, and utilize our industrial experience and professional insights to assess the reliability and adaptability of our building safety consultation solutions from the perspective of user experience.`,
            zh: `于方案中提炼各项建筑参数和要素，由数据中进行分析与量化，结合丰富的行业经验和专业理解，从用户体验视角考量建筑安全顾问咨询方案的可靠性和适应性。`,
          },
          points: {
            en: `<ul>
            <li>Fix problems in intuitive, objective, and efficient ways</li>
            <li>Utilize data to pinpoint problems</li>
            <li>Explicitly present data to provide more direct feedback</li>
            <li>Update data to facilitate real-time awareness and timely response</li>
            <li>Increase solution diversity from various perspectives</li>
            </ul>`,
            zh: `<ul>
            <li>解决问题更直观、更客观、更快速</li>
            <li>数据说话，直击问题的痛点</li>
            <li>数据显性化，反馈更直接</li>
            <li>数据更新，实时掌控，应对及时</li>
            <li>多视角增加解决方案的多样性</li>
            </ul>`,
          },
        },
      ],
    },
  },
  solutions: {
    banner: {
      image: `北京地铁意向图2.jpg`,
      imageMobile: `北京地铁意向图2-mobile.jpg`,
      title: {
        en: `Solutions`,
        zh: `行业解决方案`,
      },
    },
    titleOnly: {
      title: {
        en: `Deeply engaged in various industrial niches, Leege is able to offer customized and modularized solutions of different types and stages that serve fields including but not limited to metro, commercial buildings, infrastructure, and special public venues.`,
        zh: `通过对不同细分行业和领域的深耕细作，力景可提供不同类型、不同阶段成熟的、定制化、模块化解决方案，包括但不限于轨道交通、商业楼宇、公共基础设施、特殊公共场馆等。`,
      },
    },
    tabbedGrid: {
      section: {
        en: `Industry Solutions by Category`,
        zh: `按行业分类解决方案`,
      },
      categories: [
        {
          slug: `all`,
          title: {
            en: `Recommended`,
            zh: `推荐`,
          },
          icon: `icon-world.png`,
        },
        {
          slug: `airport`,
          title: {
            en: `Airports`,
            zh: `民航机场`,
          },
          excerpt: {
            en: `We have served in the airport industry for over 15 years, striving to build smart, technology-oriented, culturally enriched, and green airports.`,
            zh: `专注服务机场行业超过15年，助力打造智慧、科技、人文、绿色四型机场。`,
          },
          icon: `icon-plane.png`,
        },
        {
          slug: `rail`,
          title: {
            en: `Rail`,
            zh: `轨道交通`,
          },
          excerpt: {
            en: `We have accumulated 16 years of experience in implementing rail transit projects and provided all-around assurance for the safe and efficient operation of 12 metro lines. With Leege’s product, technologies, and service, it is safer and more convenient to travel by metro.
              `,
            zh: `16年轨道交通项目实施经验，为12条地铁线路的安全高效运行提供全力保障 ，力景的产品+技术+服务三重保障，让地铁运行更安全、快捷!`,
          },
          icon: `icon-transit.png`,
        },
        {
          slug: `landmark`,
          title: {
            en: `Landmark/Complex`,
            zh: `城市地标/超大综合体`,
          },
          excerpt: {
            en: `Landmark buildings are often symbols of a city, full of cultural impact, and convey the city's construction, development, and humanistic ideas with unique yet rich architectural languages. Leege delivers building’s intelligence and fire safety design based on BIM technology, facilitating safe building operation throughout the building life cycle. Whether it is a super high-rise landmark building or a super large-scale complex, there are incredibly high safety requirements. By providing software and hardware products, service, professional teams, and an effective management platform, Leege will work together to create beautiful urban skylines and landscapes.`,
            zh: `城市地标建筑往往是一座城市的象征之一，充满了文化的张力，以独特而丰富的建筑语言传递城市的建设发展和人文理念。力景基于BIM技术参与建筑智能化和消防安全设计，帮助实现建筑全生命周期的安全运营。 无论是超高层地标建筑，还是超大体量综合体对安全性功能都有极高要求，力景通过提供软件硬件产品服务， 配置专业团队，输出有效管理平台，合力共筑美丽的城市天际线和风景线。`,
          },
          icon: `icon-landmark.png`,
        },
        {
          slug: `hotel`,
          title: {
            en: `Hotel/Business`,
            zh: `酒店/商业`,
          },
          excerpt: {
            en: `High quality service and professional technology enable us to cope with the dual challenges in five-star hotels and high-end commercial sites: the good experience needs of high-net-worth customers and the high standards of international management companies.`,
            zh: `对于五星酒店及高端商业，优质服务和专业技术使我们可以从容面对双面挑战：高净值顾客的良好体验需求和国际化管理公司的高标准要求。`,
          },
          icon: `icon-building.png`,
        },
      ],
      items: [
        {
          title: {
            en: `Beijing Daxing International Airport`,
            zh: `北京大兴国际机场`,
          },
          image: `234166090.jpg`,
          category: `airport`,
        },
        {
          title: {
            en: `Beijing Capital International Airport (T1, T2, and T3)`,
            zh: `首都国际机场T1、T2、T3`,
          },
          image: `bj-capital-ariport-T3.jpg`,
          category: `airport`,
        },
        {
          title: {
            en: `Chongqing Jiangbei International Airport (T3A)`,
            zh: `重庆江北国际机场T3A`,
          },
          image: `chongqing-airport.jpg`,
          category: `airport`,
        },
        {
          title: {
            en: `Tianjin Binhai International Airport (T2)`,
            zh: `天津滨海国际机场T2`,
          },
          image: `天津滨海机场.jpg`,
          category: `airport`,
        },
        {
          title: {
            en: `Zhengzhou Xinzheng International Airport (T2)`,
            zh: `郑州新郑国际机场T2`,
          },
          image: `zhengzhou-airport-t2.jpg`,
          category: `airport`,
        },
        {
          title: {
            en: `Beijing Subway Line 4`,
            zh: `北京地铁4号线`,
          },
          image: `bj-subway-line4-crop.jpg`,
          category: `rail`,
        },
        {
          title: {
            en: `Beijing Subway Line 7`,
            zh: `北京地铁7号线`,
          },
          image: `bj-subway-line7.jpg`,
          category: `rail`,
        },
        {
          title: {
            en: `Beijing Subway Line 9`,
            zh: `北京地铁9号线`,
          },
          image: `bj-subway-line9.jpg`,
          category: `rail`,
        },
        {
          title: {
            en: `Beijing Subway Line 15`,
            zh: `北京地铁15号线`,
          },
          image: `北京地铁意向图2.jpg`,
          category: `rail`,
        },
        {
          title: {
            en: `Beijing Subway Line 6 West Extension`,
            zh: `北京地铁6号线西延`,
          },
          image: `6811730.jpg`,
          category: `rail`,
        },
        {
          title: {
            en: `Beijing Subway Changping-Line 8 Connection S1 Project `,
            zh: `昌八联络线 S1工程`,
          },
          image: `7270086.jpg`,
          category: `rail`,
        },
        {
          title: {
            en: `Beijing Subway Line 17`,
            zh: `北京地铁17号线`,
          },
          image: `37019136.jpg`,
          category: `rail`,
        },
        {
          title: {
            en: `Beijing Subway Line 12`,
            zh: `北京地铁12号线`,
          },
          image: `47901171.jpg`,
          category: `rail`,
        },
        {
          title: {
            en: `New site of China Central Television (CCTV)`,
            zh: `中央电视台新台址`,
          },
          image: `bj-cctv.jpg`,
          category: `landmark`,
        },
        {
          title: {
            en: `Universal Studios Beijing China`,
            zh: `北京环球影城主题公园`,
          },
          image: `Beijing Universal Studios Theme Park北京环球影城主题公园.jpg`,
          category: `landmark`,
        },
        {
          title: {
            en: `World Financial Center Beijing`,
            zh: `北京环球金融中心`,
          },
          image: `Beijing World Financial Center北京环球金融中心.jpg`,
          category: `landmark`,
        },
        {
          title: {
            en: `World Financial Center Tianjin`,
            zh: `天津环球金融中心`,
          },
          image: `Tianjin-World-Financial-Center天津环球金融中心-crop.jpg`,
          category: `landmark`,
        },
        {
          title: {
            en: `Genesis Beijing`,
            zh: `北京启皓中心`,
          },
          image: `Beijing Genesis Center北京启皓中心.jpg`,
          category: `landmark`,
        },
        {
          title: {
            en: `Ritz-Carlton Beijing, Financial Street`,
            zh: `北京金融街丽思卡尔顿酒店`,
          },
          image: `The Ritz-Carlton Beijing, Financial Street北京金融街丽思卡尔顿酒店.jpg`,
          category: `hotel`,
        },
        {
          title: {
            en: `The St. Regis Tianjin`,
            zh: `天津瑞吉酒店`,
          },
          image: `The St. Regis Tianjin天津瑞吉酒店.jpg`,
          category: `hotel`,
        },
        {
          title: {
            en: `Bulgari Hotel Beijing`,
            zh: `北京宝格丽酒店`,
          },
          image: `The Bulgari Hotel Beijing北京宝格丽酒店.jpg`,
          category: `hotel`,
        },
        {
          title: {
            en: `Financial Street Shopping Center Beijing`,
            zh: `北京金融街购物中心`,
          },
          image: `金融街购物中心.jpg`,
          category: `hotel`,
        },
        {
          title: {
            en: `SKP Beijing`,
            zh: `北京SKP购物中心 `,
          },
          image: `Beijing SKP Shopping Center北京SKP购物中心.jpg`,
          category: `hotel`,
        },
        {
          title: {
            en: `LIVAT Centre Beijing`,
            zh: `北京宜家荟聚购物中心`,
          },
          image: `Beijing IKEA Huiju Shopping Center北京宜家荟聚购物中心.jpg`,
          category: `hotel`,
        },
      ],
    },
  },
  join: {
    banner: {
      image: `图虫创意-915686580886700099.jpg`,
      imageMobile: `图虫创意-915686580886700099-mobile.jpg`,
      title: {
        en: `Talent Strategy`,
        zh: `人才战略`,
      },
    },
    titleOnly: {
      section: {
        en: `Talent Strategy`,
        zh: `人才战略`,
      },
      title: {
        en: `Respect and trust, integrity and accountability, happiness sharing, and customer value realization are Leege’s core values.`,
        zh: `尊重和信任，诚信与担当，分享快乐，成就客户价值—是我们的核心价值观。`,
      },
    },
    textImage: {
      body: {
        en: `<p>At Leege, we firmly believe that respect is the foundation of organization formation and team building. Respect allows every one of us to show our most appealing strength and maximize our potential.</p><p>We have always followed the spirit of respecting people, trusting people, and the mutual growth of individuals, the company, and society. We help clients and ourselves succeed by being honest and trustworthy, fulfilling clients' needs wholeheartedly, emphasizing client satisfaction, and focusing on enhancing client value improvement. Meanwhile, it is our greatest honor to be recognized and trusted as an enterprise by our employees, clients, and society.</p>`,
        zh: `<p>在力景，尊重是组织形成和团队搭建的基础，尊重让每个人展示最耀眼的闪光点，发挥最大潜能。</p><p>我们一直遵循尊重人，信任人，个人、企业、社会共同发展的理念精神。 诚实守信、尽心尽责地对待客户需求，专注客户满意度，聚焦提升客户价值，成就客户，同时成就我们自己。 同样，我们也以赢得员工、客户、社会对我们尊重和信任作为企业最高荣誉。</p>`,
      },
      image: `办公室照片11.jpg`,
    },
    textImage2: {
      highlight: {
        en: `We advocate happy work and make it part of our healthy life by creating a relaxing and comfortable office space and establishing a corporate culture of respect and trust.`,
        zh: `我们倡导快乐工作`,
      },
      body: {
        en: `<p>We encourage a team spirit of collaboration and coordination, because a great team with this team spirit is the essential guarantee for us to maintain the leading position and win recognition. Join our team and make growth more effective.</p>`,
        zh: `<p>通过营造轻松舒适的办公空间和建立尊重信任的企业文化，让快乐工作成为健康生活的一部分。</p><p>我们倡导团队精神——协作和协同，优秀的团队是我们保持领先和赢得认可最重要的保证，加入优秀的团队，让成长事半功倍。</p>`,
      },
      image: `3-crop.jpg`,
    },
    imageOnly: {
      image: `729A8561-HDR-编辑.jpg`,
    },
    textColumnsIcon: {
      icon: `icon-lightbulb_yellow.png`,
      title: {
        en: `Join Us`,
        zh: `加入我们`,
      },
      excerpt: {
        en: `<p>Want to build the future? 
        Send us a message. 
        <a href="mailto:hr@leege-tech.com"><span>hr@leege-tech.com</span></a>.`,
        zh: `<p>让我们共筑未来
        请与<a href="mailto:hr@leege-tech.com">hr@leege-tech.com</a>联系。`,
      },
      body: {
        en: `<p>At Leege, you will have the opportunity to learn, get access to training, accumulate experience, and improve yourself in technical or management career development paths planned with our assistance.</p><p>You will also have the opportunity to take challenges and stimulate your energy and creativity in our high-precision projects covering civil aviation, rail transit, smart buildings, and other fields.</p>`,
        zh: `<p>我们提供学习的机会，学习、培训、经历、积累、提升，为员工规划技术和管理职业发展双通道。</p><p>我们提供挑战的机会，民用航空、轨道交通、智慧建筑等多领域高精尖项目的参与，激发活力和创新。 </p>`,
      },
    },
  },
  contact: {
    banner: {
      title: {
        en: `Contact Us`,
        zh: `联系我们`,
      },
      image: `办公室照片11.jpg`,
      imageMobile: `办公室照片11-mobile.jpg`,
    },
    textItems: {
      section: {
        en: `Company Headquarters`,
        zh: `联系方式`,
      },
      items: [
        {
          title: {
            en: `Address`,
            zh: `地址`,
          },
          excerpt: {
            en: `A1503, Nanxincang Building, A22, Dongsi Shitiao, 
          Dongcheng District, Beijing, China`,
            zh: `中国北京东城区东四十条甲22号
          南新仓商务大厦A1503`,
          },
        },
        {
          title: {
            en: `Phone`,
            zh: `电话`,
          },
          excerpt: {
            en: `8610-6409 6339`,
            zh: `8610-6409 6339`,
          },
        },
        {
          title: {
            en: `Email`,
            zh: `邮箱`,
          },
          excerpt: {
            en: `<a href="mailto:info@leege-tech.com">info@leege-tech.com</a>`,
            zh: `<a href="mailto:info@leege-tech.com">info@leege-tech.com</a>`,
          },
        },
      ],
    },
  },
  footer: {
    default: {
      image: `729A8563-HDR2.jpg`,
      imageMobile: `729A8563-HDR2-mobile.jpg`,
      title: {
        en: `Get In Touch`,
        zh: `随时与我们联系`,
        // zh: `请随时与我们<br />联系`,
      },
      subtitle: {
        en: ``,
        zh: ``,
      },
      button: {
        to: '/join/',
        text: {
          en: 'Join Us',
          zh: '加入我们',
        },
      },
      button2: {
        to: 'mailto:info@leege-tech.com',
        isExternal: true,
        text: {
          en: 'Contact',
          zh: '联系我们',
        },
      },
    },
    join: {
      image: `729A8563-HDR2.jpg`,
      imageMobile: `729A8563-HDR2-mobile.jpg`,
      title: {
        en: `Join Us`,
        zh: `加入我们`,
      },
      subtitle: {
        en: `Let us build the future together`,
        zh: `让我们共筑未来`,
      },
    },
    contact: {
      image: `729A8674-编辑-flip.jpg`,
      imageMobile: `729A8674-编辑-flip-mobile.jpg`,
      title: {
        en: `Contact Us`,
        zh: `联系我们`,
      },
      subtitle: {
        en: `Let us build the future together`,
        zh: `让我们共筑未来`,
      },
    },
    copyright: {
      en: `力景（北京）系统技术有限公司 © Copyright Leege. All rights reserved. Credit: <a href="https://lantern.digital" target="_blank">web design</a>`,
      zh: `力景（北京）系统技术有限公司 © Copyright Leege. All rights reserved. Credit: <a href="https://lantern.digital" target="_blank">web design</a>`,
    },
  },
  menu: [
    {
      to: '/',
      text: {
        en: 'Home',
        zh: '主页',
      },
    },
    {
      to: '/about/',
      text: {
        en: 'About',
        zh: '关于我们',
      },
    },
    {
      to: '/business/',
      text: {
        en: 'Business',
        zh: '业务模式',
      },
    },
    {
      to: '/solutions/',
      text: {
        en: 'Solutions',
        zh: '解决方案',
      },
    },
    {
      to: '/join/',
      text: {
        en: 'Talent',
        zh: '人才战略',
      },
    },
    {
      to: '/contact/',
      text: {
        en: 'Contact',
        zh: '联系我们',
      },
    },
  ],
}

const iterate = (obj, locale) => {
  Object.keys(obj).forEach((key) => {
    // console.log(`key: ${key}, value: ${obj[key]}`)

    // if value has en + zh
    if (
      obj[key] &&
      typeof obj[key]['en'] !== 'undefined' &&
      typeof obj[key]['zh'] !== 'undefined'
    ) {
      obj[key] = obj[key][locale]
    }

    if (typeof obj[key] === 'object') {
      iterate(obj[key], locale)
    }
  })
  return obj
}

export const filterContentByLocale = (content, locale) => {
  const newContent = JSON.parse(JSON.stringify(content))
  const processContent = iterate(newContent, locale)
  return processContent
}
