module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-layout/gatsby-browser.js'),
      options: {"plugins":[],"component":"/buddy/leege/src/components/Layout.js"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["G-RNRJNRL4W8"]},
    },{
      plugin: require('../node_modules/gatsby-theme-i18n/gatsby-browser.js'),
      options: {"plugins":[],"defaultLang":"zh","configPath":"/buddy/leege/i18n/config.json"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
