import React from 'react'
import { Link } from 'gatsby'
import { useLocalization } from 'gatsby-theme-i18n'
import { lanLinks } from 'js/translations.js'

const LinkWrap = ({
  to,
  className,
  partiallyActive = true,
  children,
  bypassLocalization,
}) => {
  const { locale } = useLocalization()
  const thisLanLink = lanLinks[locale]
  let setTo = to
  if (!bypassLocalization) setTo = thisLanLink.urlBase + to
  return (
    <Link
      to={setTo}
      className={className}
      activeClassName='active'
      partiallyActive={partiallyActive}
    >
      {children}
    </Link>
  )
}

export default LinkWrap
