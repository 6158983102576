import React from 'react'
import LinkWrap from 'components/LinkWrap'
import LanLink from 'components/LanLink'
import Inview from 'components/Inview'
import { content, filterContentByLocale } from 'content/content.js'
import { useLocalization } from 'gatsby-theme-i18n'

const MobileMenu = ({ mobileMenuOpen, menuDown }) => {
  const { locale } = useLocalization()
  const localeContent = filterContentByLocale(content, locale)
  const { menu } = localeContent

  return (
    <div
      className={`mobile-menu ${mobileMenuOpen ? 'open' : ''}
    ${menuDown ? 'menu-down' : ''}
    `}
    >
      <Inview className='inner'>
        <ul className='menu'>
          {menu.map((item, i) => (
            <li
              key={i}
              className={`fade-in up stagger-${i * 100 + 200} ${
                mobileMenuOpen ? 'open' : ''
              }`}
            >
              <LinkWrap
                to={item.to}
                partiallyActive={item.to === '/' ? false : true}
              >
                <span>{item.text}</span>
              </LinkWrap>
            </li>
          ))}
          <li
            className={`fade-in up stagger-800 ${mobileMenuOpen ? 'open' : ''}`}
          >
            <LanLink />
          </li>
        </ul>
      </Inview>
    </div>
  )
}

export default MobileMenu
