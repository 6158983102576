import React, { useState, useEffect } from 'react'
import Header from 'components/Header'
import MobileMenu from 'components/MobileMenu'
import { globalHistory } from '@reach/router'
import { useLocalization } from 'gatsby-theme-i18n'

const Layout = ({ children }) => {
  const { locale } = useLocalization()
  const [menuDown, setMenuDown] = useState(false)
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false)
  const [lastScrollY, setLastScrollY] = useState(0)
  const toggleMobileMenuOpen = () => setMobileMenuOpen(!mobileMenuOpen)

  useEffect(() => {
    return globalHistory.listen(({ action }) => {
      if (action === 'PUSH') {
        setTimeout(() => setMenuDown(false), 400)
        setTimeout(() => setMobileMenuOpen(false), 250)
      }
    })
  }, [setMobileMenuOpen])

  const smartMenu = () => {
    if (window.scrollY > 400) {
      setMenuDown(true)
    } else {
      setMenuDown(false)
    }
  }
  if (typeof window !== 'undefined') {
    window.addEventListener('scroll', smartMenu)
  }

  return (
    <div className={`wrapper locale-${locale}`}>
      <Header
        mobileMenuOpen={mobileMenuOpen}
        menuDown={menuDown}
        toggleMobileMenuOpen={toggleMobileMenuOpen}
        addClass='banner'
      />
      <Header
        mobileMenuOpen={mobileMenuOpen}
        menuDown={menuDown}
        toggleMobileMenuOpen={toggleMobileMenuOpen}
        addClass='scrolldown'
      />
      <MobileMenu mobileMenuOpen={mobileMenuOpen} menuDown={menuDown} />
      <main>{children}</main>
    </div>
  )
}

export default Layout
